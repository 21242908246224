
import { useUserStore } from '@/store/user.ts'


export const menuData={
      usermanagecategory:[
        {
          name: 'fw',
          imgUrl: 'managerpeople.png',
          categoryId: 100001,
          router:'/serveuser',
          authority:[1,2,3,4]
        }, 
        {
          name: 'yqm',
          imgUrl: 'add_user.png',
          categoryId: 100002,
          router:'/invite',
          authority:[1,2,3,4]
        },
        {
          name: '个人信息',
          imgUrl: 'modifydata.png',
          categoryId: 100003,
          router:'/manageruserinfo',
          //router:'/user',
          authority:[1,2,3,4]
        }, 
      ],
       productcateory:[
        {
          name: '商品列表',
          imgUrl: 'goodsinfo.png',
          categoryId: 100001,
          router:'/goodslist',
          authority:[1,2,3,4]
        }, 
        {
          name: '库存列表',
          imgUrl: 'productlist.png',
          categoryId: 100003,
          router:'/stockgoodslist',
          authority:[1,2,3,4]
        }, 
        {
          name: '发货',
          imgUrl: 'exwarehouse.png',
          categoryId: 100002,
          router:'/exwarehouse',
          authority:[1,2,3,4]
        },
        {
          name: '划拨',
          imgUrl: 'huabo.png',
          categoryId: 1000012,
          router:'/exwarehousehb95',
          authority:[2]
        },
        {
          name: '退货',
          imgUrl: 'returngoods.png',
          categoryId: 100008,
          router:'/returngoods',
          authority:[2]
        },
        {
          name: '划拨',
          imgUrl: 'exwarehouse.png',
          categoryId: 1000012,
          router:'/exwarehousehb',
          authority:[1,8]
        },
        {
          name: '出库列表',
          imgUrl: 'exproductlist.png',
          categoryId: 100004,
          router:'/exwaregoodslist',
          authority:[1,2,3,4]
        }
        /*{
         name: '扫一扫',
         imgUrl: 'searche.png',
         categoryId: 100004,
         router:'/checkproinfo',
         authority:[1,2,3,4]
       }*/
      ],
       financecateory:[
        {
          name: '收入总计',
          imgUrl: 'add_user.png',
          categoryId: 100001,
          router:'/invite',
          authority:[1,4]
        },
        {
          name: '订单列表',
          imgUrl: 'goodsinfo.png',
          categoryId: 100003,
          router:'',
          authority:[1,4]
        }
      ],
      servecateory:[
       {
         name: '短信',
         imgUrl: 'sms.png',
         categoryId: 100001,
         router:'/',
         authority:[1,2,4]
       }
     ],
     /*toolcateory:[
      {
        name: '工具',
        imgUrl: 'add_user.png',
        categoryId: 100001,
        router:'/',
        authority:[2,4]
      }
    ]*/
    }