<!--首页代码 
-->

<template style="position:relative">
  <!--通知栏-->
  <div class='my-swipe'>
   <!-- <van-swipe :autoplay="3000" lazy-render>
      <van-swipe-item v-for="image in images" :key="image">
        <img :src="image" width="100" height="60"/>
      </van-swipe-item>
    </van-swipe>-->
  </div>
  <div style="position:absolute;top:40px;width:100%;left:5%;z-index:9999">
    <span style="float:left"><van-icon name="volume-o" color="#FFFF00"/></span>
    <div id="wrap" style="margin-left:20px">
    <span id="list" >
      <li>{{notifytext}}</li>
    </span>
  </div>
  </div>
  
  <div  style="position:absolute;top:10px;left:5%;z-index:9999;color:#fff">
    <span>当前用户：<van-icon name="contact" />{{username}}</span>
  </div>
  <div  style="position:absolute;top:10px;right:5%;color:#003300">
    <span @click="login_out"><van-icon name="" />退出系统</span>
  </div>
  <div v-if="getdaystoday(expiretime)<30" style="position:absolute;top:80px;font-size:14px;color:#FFCC33;right:5%;z-index:9999">
    <span >系统到期时间：{{expiretime.split(' ')[0]}},还剩{{expiredays}}天</span>
  </div>
  <!--头部内容结束-->
  <van-cell-group class="" border=true >
  <van-cell icon="apps-o"  title="rygl"/>
  <div class="category-list">
    <div v-for="item in usermanagecategory"  v-bind:key="item.categoryId" @click="onHandleRouter(item.router)">
      <div v-if="hasinauthority(item.authority)">
      <img :src="item.imgUrl">
      <span>{{item.name}}</span>
      </div>
    </div>
  </div>
  </van-cell-group>
  <van-cell-group class="" border=true >
  <van-cell icon="apps-o"  title="商品管理" />
  <div class="category-list">
    <div v-for="item in productcateory"  v-bind:key="item.categoryId" @click="onHandleRouter(item.router)">
      <div>
      <img :src="item.imgUrl">
      <span>{{item.name}}</span>
      </div>
    </div>
    <!--<div >
      <div>
      <a href="http://admin.dstgjmy.com/#/checkproinfo">
        <img src="searche.png">
      <span>扫一扫</span>
       </a>
      </div>
    </div>-->
  </div>
  </van-cell-group>
  
 <!-- <van-cell-group v-if='userlevel===2 || userlevel===1' class="">-->
  <van-cell-group v-if='userlevel===1' class="">
  <!--<van-cell icon="apps-o"  title="增值服务"/>
    
    <div class="category-list">
    <div v-for="item in servecateory" v-bind:key="item.categoryId" @click="onHandleRouter(item.router)">
      <div v-if="hasinauthority(item.authority)">
      <img :src="item.imgUrl">
      <span>{{item.name}}</span>
      </div>
    </div>
  </div>-->
    <van-cell icon="apps-o"  title="查询商品"/>
    <div >
      <div>
      <a href="http://admin.dstgjmy.com/#/checkproinfo">
        <img src="searche.png">
      <span>扫一扫</span>
       </a>
      </div>
    </div>
  </van-cell-group>
  <!--<van-cell-group v-if='userlevel===4' class="">
  <van-cell icon="apps-o"  title="财务管理"/>
    <div class="category-list">
    <div v-for="item in financecateory" v-bind:key="item.categoryId" @click="onHandleRouter(item.router)">
      <img :src="item.imgUrl">
      <span>{{item.name}}</span>
    </div>
  </div>
  </van-cell-group>-->
  <van-dialog />
</template>
<script>

import {getCurrentInstance, onBeforeMount,onMounted, ref} from 'vue'
import navBar from '@/components/NavBar'
import { useUserStore } from '@/store/user.ts'
import { menuData } from '@/config/menu_data.js'
import { useRouter } from 'vue-router'
import { Dialog } from 'vant';

export default {
  name: 'home',
  components: {
    navBar,
    [Dialog.Component.name]: Dialog.Component,
  },

  setup() {
    let userlevel=ref()
    let username=ref()
    let expiretime=ref()
    let expiredays=ref()
    let notifytext=ref()
    const userStore = useUserStore()
    console.log(userStore.userInfo.expiretime)
    userlevel=userStore.userInfo.level
    username=userStore.userInfo.name
    expiretime=userStore.userInfo.expiretime
    console.log(expiretime)
    //配置权限与router里面authority配合使用
    const  hasinauthority=(authoritys)=> {
      return authoritys.findIndex(authority=> authority === userlevel) !== -1
    }
    //组件加载之后执行
    onMounted(()=>{
      //加载最新通知
      notifytext.value=getnotifyData();
      //获取最新到期天数
      expiredays.value=getdaystoday(expiretime);
      if(expiredays.value<10){
        Dialog.alert({
          message: '系统即将于'+expiredays.value+'天到期，请尽快联系管理员！',
          theme: 'round-button',
        }).then(() => {
          // on close
        });
      }
    })
    //组件加载之前执行判断是否到期，到期退出系统
    onBeforeMount(()=>{
      if (judgeTime(expiretime)){
        login_out()
      }
    })

    const usermanagecategory=menuData.usermanagecategory
    const productcateory= menuData.productcateory.filter((item) => {
			  return item.authority.findIndex(authority=> authority === userlevel) !== -1
      })
    const financecateory=menuData.financecateory
    const servecateory=menuData.servecateory
    
    const router = useRouter()
    
    let {proxy}=getCurrentInstance();
    let tokens={'Authorization':userStore.userInfo.token}
    
    const login_out=()=>{
      proxy.$axios.post(process.env.VUE_APP_API_PORT_BASE_URL+'/user/logout','',{headers:tokens})
      userStore.setUserInfo({})
      router.push('/login');
    }
    
    //判断时间是否到期
    const judgeTime = (time) => {
        var strtime = time.replace("/-/g", "/");//时间转换
        //时间
        var date1 = new Date(strtime);
        //现在时间
        var date2 = new Date();
        //判断时间是否过期
        return date1 < date2 ? true : false;
    }
    //计算日期天数
    const getdaystoday=(time)=>{
      var strtime = time.replace("/-/g", "/");//时间转换
      let date1 = new Date(strtime);
      let date2 = new Date();
      let Days = Math.floor((date1 - date2) / 1000 / 60 / 60 / 24);
      return  Days
    }

    //读取通知文件内容
    const getnotifyData=()=>{
      let xhr = new XMLHttpRequest(),
      okStatus = document.location.protocol === "file:" ? 0 : 200;
      xhr.open("GET", "notify.txt", false); // 文件路径
      xhr.overrideMimeType("text/html;charset=utf-8"); //默认为utf-8
      xhr.send(null);
      return xhr.responseText //文本的内容
    }
    
    const VanDialog = Dialog.Component;

    return{
      userlevel,
      username,
      expiretime,
      expiredays,
      notifytext,
      usermanagecategory,
      productcateory,
      financecateory,
      servecateory,
      hasinauthority,
      login_out,
      judgeTime,
      getdaystoday,
      getnotifyData,
      VanDialog
    }
  },
};
</script>

<style lang="less" scoped >
@import '../common/style/mixin';

  #wrap {
        width: 90%;
        height:30px;
        position: relative;
        overflow: hidden;
    }

    #list {
        padding-left: 30px;
        position: absolute;
        width:100%;
        animation: 10s move infinite linear;
    }
    #list li {
        white-space: nowrap;
        list-style: none;
        width:100%;
        height:30px;
        color:rgb(32, 18, 18);
        font-size: 13px;
        text-align: center;
        padding-left: 30px;
        float:left;
    }

    @keyframes move {
        0% {
            left:0;
        }
        100% {
            left:-90%;
        }
    }
    #wrap:hover #list {
        animation-play-state: paused;
    }

  .my-swipe { 
    position: relative;
    text-align:center;
    padding-top:5%;
    color: #fff;
    font-size: 14px;
    height: 80px;
    background-color: #39a9ed;
    //background: url("../../dist/head.png");
  .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 80px;
    text-align: center;
    }
  }
  .category-list {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 20px;
    div {
      display: flex;
      width: 20%;
      flex-direction: column;
      div{
      display: flex;
      width: 100%;
      text-align: center;
      img {
        .wh(38px, 38px);
        margin: 13px auto 8px auto;
        }
      }
      div a{
      display: flex;
      width: 100%;
      text-align: center;
      flex-direction: column;
      img {
        .wh(38px, 38px);
        margin: 13px auto 8px auto;
        }
      }
    }
  }
</style>