<template>
  <div class="nav-bar van-hairline--top">
    <ul class="nav-list">
      <router-link  class="nav-list-item active" to="home">
        <!--<i class="nbicon nblvsefenkaicankaoxianban-1"><van-icon name="wap-home" /></i>-->
        <img src="home2.png">
        <span>首页</span>
      </router-link>
      <router-link  class="nav-list-item" to="manager">
        <img src="operration.png">
        <span>管理</span>
      </router-link>
      <router-link  class="nav-list-item" to="user">
        <img src="people.png">
        <span>我的</span>
      </router-link>
    </ul>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getLocal } from '@/common/js/utils'
export default {
  setup() {
    const route = useRoute()
    const store = useStore()
    onMounted(() => {
      const token = getLocal('token')
      const path = route.path
      if (token && !['/home', '/category'].includes(path)) {
        store.dispatch('updateCart')
      }
    })
    const count = computed(() => {
      return store.state.cartCount
    })
    return {
      count
    }
  }
}
</script>

<style lang="less" scoped >
    @import '../common/style/mixin';
    .nav-bar{
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 5px 0;
      z-index: 1000;
      background: #fff;
      transform: translateZ(0);
      -webkit-transform: translateZ(0);
      .nav-list {
        width: 100%;
        .fj();
        flex-direction: row;
        padding: 0;
        .nav-list-item {
          display: flex;
          flex: 1;
          flex-direction: column;
          text-align: center;
          color: #666;
          &.router-link-active {
            color: @primary;
          }
          img{
            .wh(25px, 25px);
            margin: 3px auto 5px auto;
          }
          van-icon{
            .wh(25px, 25px);
            margin: 5px auto 8px auto;
          }
          i {
            text-align: center;
            font-size: 25px;
          }
          span{
            font-size: 12px;
          }
          .van-icon-shopping-cart-o {
            margin: 0 auto;
            margin-bottom: 2px;
          }
        }
      }
    }
</style>
